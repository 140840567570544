import * as React from "react"
import { graphql } from "gatsby"
// import Layout from "../components/layout"
import Seo from "../components/seo"
import imageUrlBuilder from "@sanity/image-url"
import clientConfig from "../../client-config"
import PageBuilder from "@/components/pageBuilder"
import NewsTicker from "@/components/NewsTicker"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const IndexPage = ({ data }) => {
  const products = data.products.nodes

  const homePage = data.homepage

  const pressCoverage = data.press.nodes

  const builder = imageUrlBuilder(clientConfig.sanity)
  function urlFor(source) {
    return builder.image(source)
  }

  return (
    <div className="bg-white dark:bg-zinc-900 ">
      <Seo
        title="Home"
        description="Where science and technology meet imagination and art 💭"
        image="https://cdn.sanity.io/images/rbhjt84j/production/401f14b39a7a7b9712f4c8fc14373128cc4b3250-5920x6538.jpg?fp-x=0.5&fp-y=0.5&w=1200&h=628&fit=crop&crop=focalpoint&auto=format"
        twitterImage="https://cdn.sanity.io/images/rbhjt84j/production/401f14b39a7a7b9712f4c8fc14373128cc4b3250-5920x6538.jpg?fp-x=0.5&fp-y=0.5&w=1200&h=628&fit=crop&crop=focalpoint&auto=format"
      />
      <div
        id="hero"
        className="relative flex items-center justify-center overflow-hidden "
      >
        <div className="bg-black/[.5] py-8 relative z-30">
          <img
            src="/hero.png"
            className="lg:w-3/4 w-full mx-auto py-24"
            alt="We are imagination explorers"
          />
        </div>

        <video
          loop
          autoPlay
          playsInline
          muted
          defaultmuted="true"
          className="absolute z-10 w-auto min-w-full min-h-full max-w-none"
        >
          <source
            src="https://budmen.b-cdn.net/brandvideo-opto.mp4"
            type="video/mp4"
          />
          Your browser does not support the HTML5 video tag.
        </video>
      </div>

      {/* Page Builder Block */}
      <section>
        <PageBuilder
          pageBuilder={homePage.pageBuilder}
          _rawPageBuilder={homePage._rawPageBuilder}
        />
      </section>
      {/* Products Block */}
      <section className="inline-block my-4 w-full">
        {/* <h1 className="sm:text-3xl text-2xl font-bold mb-0 text-center text-zinc-900 dark:text-zinc-200 ">Recent Work</h1>
      <p className="text-xs text-center font-light tracking-widest text-zinc-700 dark:text-zinc-400 mb-4">FROM THE IMAGINATION STATION</p> */}
        <div className=" mx-auto grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 w-full p-4">
          {/* <img src="/cabinet.png" alt="Stylized version of the words: cabinet of curiosities" className="w-1/2 mx-auto mt-8"/> */}
          {products.map(
            (posts, index) =>
              posts.slug !== null &&
              posts.visible !== true && (
                <AniLink
                  to={"/projects/" + posts.slug.current}
                  paintDrip
                  duration={0.5}
                  hex={
                    "#" + posts.category.color !== "undefined"
                      ? posts.category.color
                      : "15b8a6"
                  }
                  className="text-zinc-300 shadow-lg dark:text-zinc-300 card-zoom"
                  key={index}
                >
                  <div
                    style={{
                      backgroundImage:
                        "url(" +
                        urlFor(posts.mainImage)
                          .width(800)
                          .auto("format")
                          .url() +
                        ")",
                    }}
                    className="inline-block card-zoom-image"
                  ></div>
                  <div className="p-2 bg-black/[0.65] hover:bg-black/[0.2] transition-all duration-500 ease-in-out transform absolute w-full h-full flex items-start flex-col justify-center text-white/[0.95]">
                    {posts.category && (
                      <span
                        style={{ background: posts.category.color }}
                        className="rounded text-[0.5rem] tracking-widest font-light uppercase justify-self-start text-white px-1 mb-2"
                      >
                        {posts.category.title}
                      </span>
                    )}
                    {/* <span className="flex-shrink mr-1 font-bold uppercase tracking-widest text-[0.5rem]">Project</span> */}
                    <h3 className="font-bold uppercase tracking-widest text-2xl">
                      {posts.title}
                    </h3>
                    <p className="text-sm font-light">{posts.excerpt}</p>
                  </div>
                </AniLink>
              )
          )}
        </div>
      </section>
      <section
        className=" text-zinc-200 body-font mb-0"
        style={{
          backgroundImage:
            "url(https://cdn.sanity.io/images/rbhjt84j/production/23d47bc59bacb258d3c7dbe95a0f7f2c1f8d2bef-1970x1212.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container px-5 py-24 bg-black/[0.75] text-zinc-200 mx-auto">
          <div className="w-full flex flex-col sm:flex-row md:justify-between justify-center sm:items-center items-center mx-auto">
            <div className="flex flex-col lg:items-start items-center">
              <h1 className="flex-grow text-xl flex flex-col gap-0.5 mb-3">
                <span className="kinescope md:text-7xl text-5xl">
                  Industrial
                </span>{" "}
                <span className="uppercase ml-16 md:text-4xl text-2xl font-bold tracking-[0.35rem]">
                  imagination
                </span>
              </h1>
              <p className="text-lg text-center mb-4">
                Our secret ingredient to bring big ideas to life.
              </p>
            </div>
            <a
              href="/projects"
              className="flex-shrink-0 text-white bg-purple-500 border-0 py-2 px-4 focus:outline-none hover:bg-purple-600 rounded font-bold tracking-widest text-xs uppercase mt-10 sm:mt-0"
            >
              Explore Projects
            </a>
          </div>
        </div>
      </section>
      <section className="bg-zinc-200">
        <NewsTicker press={pressCoverage} />
      </section>
    </div>
  )
}

export default IndexPage

export const query = graphql`
  query homePage {
    products: allSanityProducts(
      sort: { fields: [_createdAt], order: DESC }
      limit: 8
      filter: { _id: { glob: "!drafts*" }, visible: { ne: true } }
    ) {
      nodes {
        _createdAt
        mainImage {
          asset {
            url
          }
        }
        category {
          title
          color
        }
        title
        slug {
          current
        }
        excerpt
        visible
      }
    }
    homepage: sanitySiteSettings(title: { eq: "Homepage" }) {
      title
      _id
      featured {
        ... on SanityPages {
          _type
          id
          title
          excerpt
          mainImage {
            asset {
              url
            }
          }
          slug {
            current
          }
        }
        ... on SanityPost {
          id
          title
          _type
          slug {
            current
          }
          mainImage {
            asset {
              url
            }
          }
          excerpt
        }
        ... on SanityProducts {
          id
          excerpt
          _type
          slug {
            current
          }
          title
          visible
          mainImage {
            asset {
              url
            }
          }
        }
      }
      ...PageBuilderHome
    }
    press: allSanityPress(sort: { fields: [date], order: DESC }, limit: 5) {
      nodes {
        title
        source
        url
        excerpt
        date
      }
    }
  }
`
